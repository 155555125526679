
import { Injectable, Injector } from '@angular/core';
import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { HttpError } from './http-error';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';

import Swal from 'sweetalert2';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
  message: string;
  trace: string;

  constructor(
    private _injector: Injector,
    private _router: Router,
  ) { }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(req).pipe(tap(event => {

    }, exception => {
      if (exception instanceof HttpErrorResponse) {
        if (exception) {
          const ex: any = exception.error;
          this.message = JSON.stringify(ex.message);
          this.trace = JSON.stringify(ex.requestId);
        }

        switch (exception.status) {
          case HttpError.BadRequest:
            console.error('%c Bad Request 400');
            this.showMessage('400', this.message, this.trace);
            break;

          case HttpError.Unauthorized:
            console.error('%c Unauthorized 401');
            break;

          case HttpError.NotFound:
            console.error('%c Not Found 404');
            this._router.navigate(['']);
            break;

          case HttpError.TimeOut:
            console.error('%c TimeOut 408');
            break;

          case HttpError.Forbidden:
            console.error('%c Forbidden 403');
            break;

          case HttpError.InternalServerError:

            this.showMessage('500', this.message, this.trace);
            break;
        }
      }
    }));
  }


  showMessage(error, message, trace) {
    Swal.fire({
      icon: 'error',
      title: 'Ha ocurrido un error',
      html: 'TraceID: ' + trace + '<br>Error: ' + error + ' | ' + message
    });
  }
}
