import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class LocalStorageService {
  usuario: any = null;

  constructor() { }

  public getToken(): any {
    return localStorage.getItem('awsIdToken');
  }

  public getAccessToken(): any {
    return localStorage.getItem('awsAccessToken');
  }

  public setCurrentEmpresa(empresa) {
    localStorage.setItem('userInfoEmpresa', empresa);
  }

  public getCurrentEmpresa() {
    return localStorage.getItem('userInfoEmpresa');
  }

  public setUserInfo(value: any, perfil: any): any {
    localStorage.setItem('userInfoEmail', value.email.toLowerCase());
    localStorage.setItem('userInfoUsername', value.username);
    if (perfil != null) {
      localStorage.setItem('userInfoPerfil', perfil.perfil_coordinador_id);
    } else {
      localStorage.setItem('userInfoPerfil', 'empresa')
    }
    this.usuario = value;
    return value;
  }
  public getUserInfo(): any {
    let userInfo = { email: null, username: null, perfil: 'empresa', id: '1' };
    userInfo.email = localStorage.getItem('userInfoEmail');
    userInfo.perfil = localStorage.getItem('userInfoPerfil');
    userInfo.username = localStorage.getItem('userInfoUsername');
    const usernameSplit = userInfo.username.split('\\');
    if (usernameSplit.length > 1) {
      switch (userInfo.perfil) {
        case '1':
          userInfo.perfil = 'legal';
          break;
        case '2':
          userInfo.perfil = 'admin';
          break;
        case '3':
          userInfo.perfil = 'ingeniero';
          break;
        case '4':
          userInfo.perfil = 'did';
          break;
      }
      userInfo.id = '0';
      userInfo.username = usernameSplit[1].toLowerCase();
    } else {
      userInfo.id = this.getCurrentEmpresa();
    }
    return userInfo;
  }

  public getDateAccess(): any {
    return localStorage.getItem('awsAccessTokenDate');
  }

  public deleteToken(): any {
    localStorage.removeItem('awsAccessToken');
    localStorage.removeItem('awsIdToken');
    localStorage.removeItem('awsRefreshToken');
    localStorage.removeItem('awsTokenExpireValue');
    localStorage.removeItem('userInfoEmail');
    localStorage.removeItem('userInfoUsername');
    localStorage.removeItem('userInfoPerfil');
    localStorage.removeItem('userInfoEmpresa');
    localStorage.removeItem('userInfoAdministrador');
    return true;
  }

  public setAccessToken(token: string) {
    localStorage.setItem('awsAccessToken', token);
    this.setExpirate();
  }

  public setIdToken(token): any {
    localStorage.setItem('awsIdToken', token);
  }

  public setRefreshToken(token): any {
    localStorage.setItem('awsRefreshToken', token);
  }

  public getRefreshToken(): any {
    return localStorage.getItem('awsRefreshToken');
  }

  public setExpirate(): any {
    localStorage.setItem('awsTokenExpireValue', new Date().getTime().toString());
  }

  public getExpirate(): any {
    return localStorage.getItem('awsTokenExpireValue');
  }
}
