import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { SelectComponent } from './components/select/select.component';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HomeComponentComponent } from './main/home-component/home-component.component';
import { HeaderComponent } from './header/header/header.component';
import { FooterComponent } from './footer/footer/footer.component';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { LoginComponent } from './account/login/login.component';
import { FormsModule } from '@angular/forms';
import { ReloadComponent } from './components/reload/reload.component';
import { ErrorInterceptor } from './interceptors/app.interceptor';
import { AcercaDeComponent } from './main/acerca-de/acerca-de.component';

@NgModule({
  declarations: [
    AppComponent,
    SelectComponent,
    HomeComponentComponent,
    HeaderComponent,
    FooterComponent,
    LoginComponent,
    ReloadComponent,
    AcercaDeComponent,

  ],
  imports: [
    BrowserModule,
    FormsModule,
    AppRoutingModule,
    HttpClientModule,

  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
