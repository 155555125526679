import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { LocalStorageService } from '../localStorage/local-storage.service';
import Swal from 'sweetalert2';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class CognitoService {
  headers: HttpHeaders;
  private accessToken: string;
  private idToken: string;
  private isLogged: boolean;
  private user: any;
  usuario: any = null;
  i = 0;
  api_auth_url = environment.API_AUTH_ENDPOINT;

  constructor(
    public http: HttpClient,
    private localStorage: LocalStorageService,
    private router: Router
  ) {
    this.headers = new HttpHeaders({
      'Content-type': 'application/x-www-form-urlencoded',
      'Authorization': environment.CONFIG.BASIC_AUTH
    });
  }

  setIdToken(token) {
    this.idToken = token;
  }

  setAccessToken(token) {
    this.accessToken = token;
  }

  setCurrentEmpresa(empresa) {
    this.localStorage.setCurrentEmpresa(empresa);
  }

  getCurrentEmpresa() {
    return this.localStorage.getCurrentEmpresa();
  }

  getIdToken() {
    return this.localStorage.getToken();
  }

  getAccessToken() {
    return this.accessToken;
  }

  getTokens(code) {
    this.headers = new HttpHeaders({
      'Content-Type': 'application/x-www-form-urlencoded',
      'Authorization': environment.CONFIG.BASIC_AUTH
    });
    const url = environment.CONFIG.URL_COGNITO + '/oauth2/token';
    const body = new URLSearchParams();
    body.set('grant_type', 'authorization_code');
    body.set('client_id', environment.CONFIG.CLIENT_ID);
    body.set('redirect_uri', environment.CONFIG.LOGIN_CALLBACK);
    body.set('code', code);
    return new Promise((resolve, reject) => {
      this.http.post(url, body.toString(), { headers: this.headers }).subscribe((res: any) => {
        this.localStorage.setIdToken(res.id_token);
        this.localStorage.setAccessToken(res.access_token);
        this.localStorage.setRefreshToken(res.refresh_token);
        this.getUserInfo().then(() => {
          resolve(res);
        })
      });
    });
  }

  refreshTokens() {
    const url = environment.CONFIG.URL_COGNITO + '/oauth2/token';
    const body = new URLSearchParams();
    body.set('grant_type', 'refresh_token');
    body.set('client_id', environment.CONFIG.CLIENT_ID);
    body.set('refresh_token', this.localStorage.getRefreshToken());
    return new Promise((resolve, reject) => {
      this.http.post(url, body.toString(), { headers: this.headers }).subscribe((res: any) => {
        this.localStorage.setIdToken(res.id_token);
        this.localStorage.setAccessToken(res.access_token);
        this.getUserInfo().then(() => {
          resolve(res);
        });
      }, (error: any) => {
        if (this.i === 0) {
          alert('La sesión ha expirado, debe iniciar sesión nuevamente.');
          this.signOut();
        }
        this.i++;
      });
    });
  }

  getUserInfoFromLocalStorage() {
    return this.localStorage.getUserInfo();
  }

  getUserInfo() {
    const url = environment.CONFIG.URL_COGNITO + '/oauth2/userInfo';
    const headers = new HttpHeaders({
      'Authorization': 'Bearer ' + this.localStorage.getAccessToken()
    });
    return new Promise((resolve, reject) => {
      this.http.get(url, { headers: headers }).subscribe((usuario: any) => {
        const usernameSplit = usuario.username.split('\\');
        if (usernameSplit.length > 1) { //USER IS FROM COORDINADOR
          this.getPerfilUsuarioCoordinador(usernameSplit[1]).then(perfil => {
            if (perfil == null) {
              alert('El usuario no tiene perfil para acceder a la aplicación.');
              window.location.href = environment.CONFIG.LOGOUT_LINK;
              reject('usuario coordinador no tiene perfil en la app');
            } else {
              this.localStorage.setUserInfo(usuario, perfil);
              resolve(usuario);
            }
          })
        } else { //USER IS FROM EMPRESA
          this.getEmpresasUsuario(usuario.username).then((empresas: any) => {
            if (empresas.length) {
              let empresa = this.getCurrentEmpresa();
              if (empresa == null) {
                empresa = empresas[0].rut;
              }
              this.setCurrentEmpresa(empresa);
              this.localStorage.setUserInfo(usuario, null);
            } else {
              Swal.fire({
                title: 'Información',
                text: 'El usuario no tiene empresas asociadas.',
                showConfirmButton: true,
                confirmButtonText: 'Aceptar',
                showCloseButton: true
              }).then((result: any) => {
                if (result.value) {
                  this.router.navigate(['/']);
                }
              });
            }
            resolve(usuario);
          });
        }
      });
    });
  }

  getPerfilUsuarioCoordinador(correo: string) {
    const url = environment.API_AUTH_ENDPOINT + `/usuario/coordinador?correo=${correo.toLowerCase()}`;
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': this.localStorage.getToken()
    });
    return new Promise((resolve, reject) => {
      this.http.get(url, { headers: headers }).subscribe((res: any) => {
        resolve(res);
      });
    });
  }

  getEmpresasUsuario(username) {
    const url = environment.API_AUTH_ENDPOINT + `/usuario/${username}/empresas`;
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': this.localStorage.getToken()
    });
    return new Promise((resolve, reject) => {
      this.http.get(url, { headers: headers }).subscribe((res: any) => {
        resolve(res);
      });
    });
  }

  getTokenValidity() {
    const expireDate = this.localStorage.getExpirate();
    if (expireDate == null) {

      return false;
    }
    const now = new Date().getTime();
    const result = now - expireDate;
    if (result > 3600000) {

      this.refreshTokens().then(() => {

        Swal.fire({
          title: 'Sesión Renovada',
          text: 'Se ha renovado la sesión exitosamente.',
          confirmButtonText: 'Aceptar',
          confirmButtonColor: '#3ca7ac'
        }).then((result) => {
          if (result.value) {
            const currentUrl = this.router.routerState.snapshot.url;
            this.router.navigateByUrl('reload' + currentUrl);
          }
        });
      });
    } else {

      return true;
    }
  }

  getPerfilesCoordinador(id) {
    const url = environment.API_AUTH_ENDPOINT + `/perfil-coordinador/tipos/${id}`;
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': this.localStorage.getToken()
    });
    return new Promise((resolve, reject) => {
      this.http.get(url, { headers: headers }).subscribe((res: any) => {
        resolve(res);
      });
    });
  }

  getEmpresas(rut) {
    const url = environment.API_AUTH_ENDPOINT + `/usuario/${rut}/empresas`;
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': this.localStorage.getToken()
    });
    return new Promise((resolve, reject) => {
      this.http.get(url, { headers: headers }).subscribe((res: any) => {
        resolve(res);
      });
    });
  }

  signOut() {
    // window.location.href = environment.CONFIG.LOGOUT_LINK;
    window.location.href = "/"
    this.localStorage.deleteToken();
  }

  isAuthenticated() {
    this.getTokenValidity();
    const token = this.localStorage.getToken();
    return token != null;
  }

  searchEmpresas(nombre) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': environment.CONFIG.BASIC_AUTH
    });

    const url = this.api_auth_url + '/search';
    const body = {
      filter: nombre,
      method: 'SENSITIVE_EMPRESA',
    };
    return this.http.post(url, body, { headers: headers });
  }


}
