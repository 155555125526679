import { Component, OnInit } from '@angular/core';
import { CognitoService } from 'src/app/services/cognito/cognito.service';
import { Router, ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  username: any = '';
  password: any = '';
  loading = false;
  code: any;
  token: any;

  constructor(
    public cognito: CognitoService,
    private router: Router,
    private route: ActivatedRoute
  ) { }

  ngOnInit() {
    this.route.paramMap.subscribe(params => {
      this.code = this.route.snapshot.queryParamMap.get('code');
      if (this.code == null) {
        this.router.navigate(['/']);
      } else {
        this.cognito.getTokens(this.code).then(res => {
          window.location.href = '/'
        })
      }
    })
  }

}
