import { Component, OnInit, Input, Output, EventEmitter, forwardRef, NgZone, HostListener, ElementRef } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  selector: 'app-select',
  templateUrl: './select.component.html',
  styleUrls: ['./select.component.scss'],
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => SelectComponent),
    multi: true
  }]
})
export class SelectComponent implements OnInit {

  _value = '';
  _validate: boolean;
  _isValid: boolean;
  _selectedOption: string;
  _customOption: string;
  isOpen: any;
  currentOption: string;

  constructor(
    private ngZone: NgZone,
    private dropdown: ElementRef
  ) { }

  @Input() id: string;
  @Input() data: [];
  @Input() label: string;
  @Input() firstOption: string;
  @Input() defaultOption: string;
  @Input() customOption: string;
  @Input()
  set selectedOption(value: string) {
    this._selectedOption = value;
  }

  @Input() value = '';
  @Input() valueKey: string;
  @Input() name: string;
  @Input() nameKey: string;
  @Input() smallText: string;
  @Input()
  set validate(validate: boolean) {
    this._validate = validate;
    this.requiredField(this._value);
  }
  @Input() isValid: boolean;
  @Input() isInvalidText: string;
  @Input() theme: string;
  @Input() type: string;
  @Input() customText: string;
  @Output() onChange = new EventEmitter<any>();
  disabled: boolean;

  propagateChange: any = () => { };

  writeValue(obj: any): void {
    if (obj) {
      this._value = this.value;
    }
  }

  setDisabledState?(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }
  registerOnChange(fn) {
    this.propagateChange = fn;
  }
  registerOnTouched(fn: () => void): void { }

  onChangeSelect(event) {
    // 
    this.onChange.emit(event);
    const value = event.target.value;
    this.propagateChange(value);
    this.requiredField(value);
  }


  requiredField(value) {
    if (value.length !== 0) {
      this._isValid = true;

    } else {
      this._isValid = false;
      this.isInvalidText = 'Este campo es requerido.';
    }
  }

  ngOnInit() {
    this.currentOption = this.firstOption;
    this._customOption = this.customOption;
    this._value = this.value;
    this.defaultNameKey();
    this.requiredAttributes();
  }

  defaultNameKey() {
    if (!this.nameKey) {
      this.nameKey = 'name';
    }
    if (!this.valueKey) {
      this.valueKey = 'value';
    }
  }

  requiredAttributes() {
    if (this.id == null) {
      throw new Error('You must specify an ID for your input.');
    }
    if (this.id == null) {
      throw new Error('You must specify a name for your input.');
    }
    if (this.label == null) {
      console.warn('You must specify a label for your input.');
    }
  }

  toggleOpen(disabled?) {
    if (!disabled) {
      this.isOpen = !this.isOpen;
    }
  }

  selectOption(option) {
    const value = option.target.dataset.value;
    const text = option.target.textContent;
    this._value = value;
    this.currentOption = text;
    this.onChange.emit(value);
    this.propagateChange(value);
    this.requiredField(value);
    this.toggleOpen();
  }

  closeOutsideSidenav(e) {
    this.ngZone.run(() => {
      this.toggleOpen();
    })
  }

  @HostListener('document:click', ['$event'])
  @HostListener('document:touchstart', ['$event'])
  toggle(event) {
    if (!this.dropdown.nativeElement.contains(event.target)) {
      this.isOpen = false;
    }

  }
}