import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-home-component',
  templateUrl: './home-component.component.html',
  styleUrls: ['./home-component.component.scss']
})
export class HomeComponentComponent implements OnInit {

  constructor(private router: Router) { }

  ngOnInit() {

  }

  goTo(event) {
    switch (event) {
      case 'observaciones':
        window.location.href = `${window.location.href}observaciones`
        break;

      case 'contratos':
        window.location.href = `${window.location.href}contratos`
        break;
    }
  }
}
