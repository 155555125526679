import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomeComponentComponent } from './main/home-component/home-component.component';
import { LoginComponent } from './account/login/login.component';
import { ReloadComponent } from './components/reload/reload.component';
import { AcercaDeComponent } from './main/acerca-de/acerca-de.component';


const routes: Routes = [
  {
    path: '',
    component: HomeComponentComponent
  },
  {
    path: 'account/login',
    component: LoginComponent
  },
  {
    path: 'acerca-de',
    component: AcercaDeComponent
  },
  {
    path: 'reload',
    children: [{
      path: '**',
      component: ReloadComponent
    }]
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
