// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  CONFIG: {
    CLIENT_ID: '18ptol9o71lf3g05hecf83nh4d',
    BASIC_AUTH: 'Basic MThwdG9sOW83MWxmM2cwNWhlY2Y4M25oNGQ6dG1mMjkzN2QxZzkwNGNsMGZza2ZvbDg0b2dyZGhzN2dpczVoMjQ3NXY5ZzFkMmk0N3A4',
    URL_COGNITO: 'https://qa-auth.coordinador.cl',
    LOGIN_CALLBACK: 'http://localhost:4200/account/login',
    LOGIN_LINK: `https://qa-auth.coordinador.cl/login?response_type=code&client_id=18ptol9o71lf3g05hecf83nh4d&redirect_uri=http%3A%2F%2Flocalhost%3A4200%2Faccount%2Flogin`,
    LOGOUT_LINK: 'https://qa-auth.coordinador.cl/logout?response_type=code&client_id=18ptol9o71lf3g05hecf83nh4d&logout_uri=http%3A%2F%2Flocalhost%3A4200'
  },
  API_AUTH_ENDPOINT: 'https://hiaqgt0ot9.execute-api.us-east-1.amazonaws.com/qa'
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
