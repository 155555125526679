import { Component, OnInit, Input, Renderer2 } from '@angular/core';
import { CognitoService } from 'src/app/services/cognito/cognito.service';
import { environment } from 'src/environments/environment'
@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
  loginLink: string = environment.CONFIG.LOGIN_LINK;
  @Input() type: string;
  @Input() loggedIn: string = 'false';
  user: any;
  token: any;
  dateToken: any;
  now = +new Date();
  scroll: any;
  isToggled: any;
  empresa: any;
  empresas: any;

  constructor(
    public cognito: CognitoService,
    public renderer: Renderer2
  ) { }

  ngOnInit() {
    if (this.cognito.isAuthenticated()) {
      this.user = this.cognito.getUserInfoFromLocalStorage();
      if (this.user.perfil == 'empresa') {
        this.cognito.getEmpresasUsuario(this.user.username).then(res => {
          this.empresas = res;
          this.empresa = this.cognito.getCurrentEmpresa();
          if (this.empresa == null || this.empresa == 'undefined') {
            this.empresa = this.empresas[0].rut_empresa;
          }
          this.cognito.setCurrentEmpresa(this.empresa);
        });
      }
      this.loggedIn = 'true';
    } else {
      this.loggedIn = 'false'
    }
    this.renderer.listen(window, 'scroll', $event => {
      this.scroll = window.scrollY;
    })
  }

  onSelectEmpresa(empresa) {
    this.empresa = empresa;
    this.cognito.setCurrentEmpresa(this.empresa);
    window.location.reload();
  }

  toggleMenu() {
    this.isToggled = !this.isToggled;
  }

  closeSession() {
    this.cognito.signOut();
  }
}
